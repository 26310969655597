<style>
  .card_content_box{
    /* background: url(../../assets/image/loginbgsm.png); */
    background-size: 100% !important;
    background-repeat: no-repeat!important;
  }
  .card_box{
    background: url(../../assets/image/usercord.png);
    padding: 30px 0 29px 19px;
    width: 92%;
    height: 190px;
    overflow: hidden;
    box-sizing: border-box;
    margin: 0 auto;
        background-size: 100% 100%;
        position: fixed;
        /* top: 27%; */
        /* top: 32%; */
        left: 0;
        right: 0;
  }
  .card_box>div{
    float: left;
  }
  .roleimg>img{
        height: 100%;
  }
  .roleimg{
    height: 100%;
    margin-right: 19px;
  }
  .roleinformation_top{
    font-size: 13px;
    font-weight: 400;
    color: rgba(255,255,255,.48);
    margin-bottom: 1px;
  }
  .roleinformation_bot{
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    margin-bottom: 9px;
  }
  .olduser{
        /* padding-top: 18%; */
        /* padding-top: 36%; */
    font-size: 15px;
    font-weight: 400;
    color: #FFFFFF;
    padding-left: 19px;
  }
  .return_top>img{
    width: 24px;
    height: 24px;
  }
  .return_top{
    padding: 20px 0 0 10px;
  }
</style>
<template>
  <div class="card_content_box" @click="gotobook" :style="{height: windowHeight + 'px','background': 'url(' + srcImgUrl + ')'}">
    <div class="return_top">
      <img src="../../assets/image/loginreturnwhite.png" alt="">
    </div>
    <div class="olduser" v-show="oldusertext" :style="{marginTop:oldusermarginTop}">
      亲爱的旅者，你已经领取过身份了哦
    </div>
    <div class="card_box" :style="{top:card_boxtop}">
      <div class="roleimg">
        <img src="../../assets/image/roleimage.png" alt="">
      </div>
      <div class="roleinformation">
        <div class="roleinformation_top">身份</div>
        <div class="roleinformation_bot">旅行者</div>
        <div class="roleinformation_top">身份ID</div>
        <div class="roleinformation_bot">{{userid}}</div>
        <div class="roleinformation_top">住址</div>
        <div class="roleinformation_bot">梦想世界创作星球</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      oldusermarginTop: '18%',
      card_boxtop: '27%',
      windowHeight: document.documentElement.clientHeight,
      // srcImgUrl: require('../assets/image/loginbgsm.png')
      userid: '',
      // oldusertext: false,
      srcImgUrl: require('../../assets/image/loginbgsm.png'),
      oldusertext: false
    }
  },
  created () {
    // 根据设备选择对应背景显示
    if (this.windowHeight > 736) {
      this.srcImgUrl = require('../../assets/image/loginbgbig.png')
      this.oldusermarginTop = '36%'
      this.card_boxtop = '32%'
    }
    this.axios({
      method: 'get',
      url: '/v1/getUserId',
      params: {}
    }).then((res) => {
      // console.log(res)
      if (res != null) {
        // console.log(res)
        this.userid = res.data
      }
    })
    // console.log(JSON.parse(sessionStorage.getItem('isNewUser')))
    if (JSON.parse(sessionStorage.getItem('isNewUser'))) {
      this.oldusertext = false
    } else {
      this.oldusertext = true
    }
    setTimeout((e) => {
      this.gotobook()
    }, 3000)
  },
  methods: {
    gotobook () {
      window.location.href = '/book'
    }
  }
}
</script>
